import { MvReview } from '@/lib/strapi-types/MvReview';
import { DevBackEndpoints } from '@/lib/types/enums/backEndpoints.enum';
import { Strapi4ResponseMany } from '@nuxtjs/strapi/dist/runtime/types';
import { stringify } from 'qs';

interface UseFindReviewsProps {
  filters?: Record<string, unknown>;
  populate?: string[];
}

export async function useFindReviews({ filters = {}, populate }: UseFindReviewsProps = {}) {
  const defaultPopulate = [''];
  const allPopulate = populate ? [...defaultPopulate, ...populate] : defaultPopulate;

  const parsedQuery = stringify({
    populate: allPopulate,
    filters,
    sort: ['date:desc'],
    pagination: {
      start: 0,
      limit: 15,
      withCount: true
    }
  });

  const { data } = await useFetchWithHead<Strapi4ResponseMany<MvReview['attributes']>>(
    `${useCompleteUrl(DevBackEndpoints.REVIEWS)}?${parsedQuery}`
  );

  return { reviews: data.value?.data ?? [] };
}
