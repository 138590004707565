<template>
  <div v-if="reviews?.length && reviews?.length > 0" class="section-ratings">
    <div class="head">
      <div class="title">Avis voyageurs</div>
      <NuxtLink class="more" to="/marco-vasco-avis/">Voir tous les avis</NuxtLink>
    </div>
    <SwiperArrows v-model="swiper" class="arrows" color="black" />
    <SwiperWrapper
      v-model="swiper"
      class="content"
      :slides-per-view="'auto'"
      :space-between="24"
      loop
      centered-slides
    >
      <SwiperSlide v-for="(review, i) of reviews" :key="i" class="rating">
        <div class="bubble-container">
          <div class="bubble">
            <div
              v-if="review.attributes.description"
              class="inner"
              :class="{ scroll: review.attributes.description?.length > MAX_CHARS && expendable }"
            >
              <Icon class="quote" name="quote" fill="rgba(0 0 0 / 75%)" />

              <div
                v-if="review.attributes.description"
                class="text"
                :class="{
                  expendable: review.attributes.description?.length > MAX_CHARS && !expendable
                }"
              >
                {{ review.attributes.description }}
              </div>
            </div>
            <div v-if="review.attributes.description" class="more-infos">
              <div
                v-if="review.attributes.description?.length > MAX_CHARS"
                class="more"
                @click="expendable = !expendable"
              >
                {{ expendable ? 'Voir moins' : 'Voir plus' }}
              </div>
              <div class="value">
                <Icon class="star" name="star" fill="var(--color-primary-red)" />
                <div class="itself">
                  {{ review.attributes.rating }}
                  <div class="max">/10</div>
                </div>
              </div>
            </div>
          </div>
          <div class="triangle" />
        </div>
        <div class="bottom">
          <div class="author">{{ review.attributes.name }}</div>
          <div v-if="review.attributes.date" class="date">
            {{ transformerDate(review.attributes.date.toString()) }}
          </div>
          <div
            v-if="review.attributes.country && review.attributes.country.data"
            class="author-title"
          >
            Voyage {{ review.attributes.country.data.attributes.preposition }}
            {{ review.attributes.country.data.attributes.label }}
          </div>
          <div
            v-if="showProduct && review.attributes.journey?.data?.attributes?.title"
            class="product"
          >
            {{ review.attributes.journey?.data?.attributes?.title }}
          </div>
        </div>
      </SwiperSlide>
    </SwiperWrapper>
  </div>
</template>

<script lang="ts" setup>
import { SwiperBinding } from '@/lib/types/swiper';
import { transformerDate } from '@/lib/utils';
const { showProduct = true } = defineProps<{
  showProduct?: boolean;
}>();
const MAX_CHARS = 300;
const expendable = $ref<boolean>(false);
const swiper = $ref<SwiperBinding>();
const route = useRoute();
const { pushToDataLayer } = useDataLayer();
let filters = {};

onMounted(() => {
  pushToDataLayer({});
});

if (route.params) {
  if (route.params.tourid) {
    filters = { journey: { slug: route.params.tourid } };
  } else if (route.params.countryid) {
    filters = { country: { slug: route.params.countryid } };
  } else if (route.params.continentid) {
    filters = { country: { continents: { slug: route.params.continentid } } };
  }
}
const [{ reviews }] = await Promise.all([
  useFindReviews({
    filters,
    populate: ['country', 'journey']
  })
]);
</script>

<style lang="scss" scoped>
@use 'sass:math';
@use '$/breakpoints.scss';
@use '$/colors.scss';
@use '$/metrics.scss';
@use '$/misc.scss';
@use '$/mouse.scss';
@use '$/text-ellipsis.scss';
@use '$/z-index.scss';

.section-ratings {
  $mobile-margin: 24px;

  position: relative;

  flex-direction: column;
  gap: 16px;
  align-items: center;

  padding: 60px 0;

  @include breakpoints.mobile() {
    gap: 0;
    padding: 40px 0;
  }

  .head {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    column-gap: 20px;
    justify-content: center;

    width: metrics.$inner-width;

    @include breakpoints.mobile() {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 28px;
      width: 100%;
      padding: 0 $mobile-margin;
    }

    .title {
      grid-column-start: 2;
      justify-content: center;
      font-size: 30px;
      font-weight: 800;

      @include breakpoints.mobile() {
        grid-column-start: 1;
        justify-content: flex-start;
        font-size: 20px;
        line-height: 24px;
      }
    }

    .more {
      @include misc.bold-link();

      grid-column-start: 3;
      align-items: center;
      justify-self: end;

      @include breakpoints.mobile() {
        grid-column-start: 2;
        margin-top: 1px;
      }
    }
  }

  .arrows {
    position: absolute;
    top: 270px;
    right: auto;
    left: auto;

    gap: 395px;

    @include breakpoints.mobile() {
      top: 244px;
      gap: calc(100vw - 72px);
    }

    :deep(.left),
    :deep(.right) {
      z-index: z-index.$any-on-top;

      .icon {
        @include breakpoints.mobile() {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  .content {
    padding-top: 24px;

    :deep(.rating) {
      flex-direction: column;
      gap: 18px;
      opacity: 0.2;
      transition: opacity 0.2s;

      @include breakpoints.mobile() {
        gap: 12px;
      }

      &.swiper-slide-active {
        opacity: 1;

        .bubble {
          box-shadow: 1px 1px 32px rgb(0 0 0 / 13%);
        }
      }

      .bubble-container {
        flex-direction: column;

        .bubble {
          flex-direction: column;
          gap: 6px;

          width: 430px;
          height: 222px;
          padding: 26px 32px;

          border-radius: 7px;

          transition: box-shadow 0.2s;

          @include breakpoints.mobile() {
            gap: 9px;
            width: calc(100vw - #{$mobile-margin * 2});
            height: 202px;
            padding: 20px 24px;
          }

          .inner {
            overflow: hidden;
            flex-grow: 1;
            gap: 18px;
            align-items: flex-start;

            @include breakpoints.mobile() {
              gap: 16px;
            }

            .quote {
              margin-top: 5px;
            }

            .text {
              font-size: 16px;
              font-weight: 500;
              line-height: 28px;

              @include breakpoints.mobile() {
                margin-top: 5px;
                font-weight: 400;
                line-height: 24px;
              }
            }

            .expendable {
              @include text-ellipsis.multi-line($lines: 5);
            }
          }

          .scroll {
            overflow: scroll;
            height: 142px;
            padding-bottom: 5px;

            &::-webkit-scrollbar {
              width: 6px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
              background: white;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: #a2a1a1;
              border-radius: 10px;
            }
          }

          .more-infos {
            width: 100%;

            .more {
              @include misc.bold-link();

              margin-left: 60px;
              font-size: 14px;
            }

            .value {
              transform: translateX(10px);

              gap: 8px;
              align-items: center;
              justify-content: flex-end;

              margin-left: auto;

              @include breakpoints.mobile() {
                transform: translateX(0);
              }

              .star {
                width: 12px;
              }

              .itself {
                align-items: center;
                font-size: 20px;
                font-weight: 700;
                color: colors.$primary-red;

                .max {
                  margin-top: 2px;
                  font-size: 16px;
                  font-weight: 400;
                }
              }
            }
          }
        }

        .triangle {
          $width: 30px;
          $half: math.div($width, 2);

          height: 15px;
          background-color: white;
          clip-path: polygon(calc(50% - #{$half}) 0, calc(50% + #{$half}) 0, 50% 100%);
        }
      }

      .bottom {
        flex-direction: column;
        gap: 8px;
        align-items: center;

        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        color: colors.$grey-900;

        @include breakpoints.mobile() {
          gap: 6px;
          font-weight: 600;
          line-height: 17px;
        }

        .author {
          margin-bottom: 5px;
          font-size: 14px;
          line-height: 17px;
          color: colors.$primary-red;
        }

        .author-title {
          max-width: 300px;
          margin-top: 10px;
          text-align: center;

          @include breakpoints.mobile() {
            max-width: 200px;
          }
        }

        .product {
          justify-content: center;
          width: 225px;
          margin-top: 8px;
          text-align: center;
        }
      }
    }
  }
}
</style>
